<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 justify-content-center">
      <div class="tbb-bg-image">
        <b-img
          :src="bgImage"
          alt="BeautiBook - Login"
        />
      </div>

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <tbb-logotype />
        <h2 class="text-white tbb-logo mb-0">
          <span class="font-weight-light">Beauti</span>Book
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Login-->
      <b-col
        v-if="!$auth.loading"
        lg="4"
        class="d-flex align-items-center"
      >
        <div class="mx-auto text-center text-white">
          <b-alert
            variant="success"
            :show="message != undefined && message.length > 0"
          >
            <p class="px-2">
              {{ message }}
            </p>
          </b-alert>
          <div class="mx-auto text-center text-white mw-350">
            <b-card-title
              class="mb-1 font-weight-normal text-white"
              title-tag="h2"
            >
              Welcome to
              <b-img
                :src="logoLight"
                alt="BeautiBook - Logo - light"
                class="d-block mx-auto mt-1"
              />
            </b-card-title>
            <b-card-text class="mb-2">
              Please sign-in to your stylist account and start the adventure
            </b-card-text>

            <!-- submit buttons -->
            <b-button
              v-if="!logging"
              class="btn btn-primary btn-block"
              type="submit"
              variant="primary"
              @click="login"
            >
              Sign in
            </b-button>
          </div>
        </div>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BLink, BCardText, BCardTitle, BImg, BButton, BAlert,
} from 'bootstrap-vue'
import TbbLogotype from '../../sites/TbbLogotype.vue'
import TbbBg from '../../../assets/images/pages/tbb-bg.jpg'
import TbbLogoLight from '../../../assets/images/logo/tbb-logo-light.svg'

export default {

  components: {
    BRow,
    BCol,
    BLink,
    BAlert,
    BCardText,
    BCardTitle,
    BImg,
    BButton,
    TbbLogotype,
  },

  data() {
    return {
      logging: false,
      bgImage: TbbBg,
      logoLight: TbbLogoLight,
      message: undefined,
    }
  },
  created() {
    this.message = this.$route.query.message
    this.$authStylist.initClient()
      .then(() => this.login())
  },
  methods: {
    login() {
      this.logging = true
      this.$auth.loginWithRedirect()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import 'src/assets/scss/elements/login-page.scss';
</style>
